import React, { useEffect, useState } from "react";
import "./slideShow.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const SlideShow = () => {
  const [responsiveItems, setResponsiveItems] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the number of items based on screen width
      if (window.innerWidth < 1000) {
        setResponsiveItems(1); // Set to 1 item for mobile screens
      } else {
        setResponsiveItems(3); // Set to 3 items for larger screens
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(
    () => {
      // You can perform any side effects based on props changes here
      // For example, if props are changing, you can trigger some action here
    },
    [
      /* list of props that you want to watch for changes */
    ]
  );

  const options = {
    loop: true,
    responsiveClass: true,
    center: true,
    items: responsiveItems,
    nav: true,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    pagination: false,
    dots: false,
  };

  return (
    <div className="max-width">
      <div className="slideShow">
        <OwlCarousel className="owl-theme" {...options}>
          <div className="item">
            <img src={require("../../img/2x/a01.jpg")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/a02.jpg")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/a03.jpg")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/a04.jpg")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/a05.jpg")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/a06.jpg")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/a07.jpg")} alt="" />
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default SlideShow;
