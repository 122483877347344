

import React, { useState, useEffect } from "react";
import "./movingText.scss"; // Import CSS file for styling

const MovingText = () => {
  const [position, setPosition] = useState(0);
  const containerRef = React.useRef(null);
  const textRef = React.useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    const text = textRef.current;

    if (container && text) {
      setContainerWidth(container.offsetWidth);
      setTextWidth(text.offsetWidth);
    }

    const interval = setInterval(() => {
      setPosition((prevPosition) => {
        // Move the text to the left
        let newPosition = prevPosition - 1;

        // If the text has moved beyond the container width, reset its position to the start
        if (newPosition < -textWidth) {
          newPosition = containerWidth;
        }

        return newPosition;
      });
    }, 10);

    return () => clearInterval(interval);
  }, [containerWidth, textWidth]);

  return (
    <div className="moving-text-container" ref={containerRef}>
      <div
        className="moving-text"
        ref={textRef}
        style={{
          marginLeft: `${position}px`,
        }}
      >
        <h1 className="gradient-text">
          XL - Fresh Potato Chips​​ | XL - Xtreme​ | Flaming Hot | Creamy
          Cheddar | Thai Sweet Chili | XL - Baked​ | Jalapeno Basil | Black
          Pepper | Vintage Cheese | Twister - Corn Snacks​​ | Nacho Cheese |
          Himalayan Salt | Honey Barbeque | XL - Sticks​ | Mild Spicy | Thai
          Campfire | Tomato | Original | Sea Salt | XL - Fresh Potato Chips​​ |
          XL - Xtreme​ | Flaming Hot | Creamy Cheddar | Thai Sweet Chili | XL -
          Baked​ | Jalapeno Basil | Black Pepper | Vintage Cheese | Twister -
          Corn Snacks​​ | Nacho Cheese | Himalayan Salt | Honey Barbeque | XL -
          Sticks​ | Mild Spicy | Thai Campfire | Tomato | Original | Sea Salt
        </h1>
      </div>
    </div>
  );
};

export default MovingText;
