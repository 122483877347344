import React, { useState } from "react";
import "./Cart.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, resetCart } from "../../redux/cartReducer";
import { loadStripe } from "@stripe/stripe-js";
import { makeRequest } from "../../makeRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CancelIcon from "@mui/icons-material/Cancel";

const Cart = () => {
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [close, setClose] = useState(false);

  const delivaryAmout = 15;
  const minimum = 50;

  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => total += (item.quantity * item.price))
    return total.toFixed(2);
  };

  {/*
  const dicountPrice = () =>{
    const discountRate = (netOder * 15) / 100;
    return discountRate.toFixed(2)
  }
*/}

  const netOder = Math.min(totalPrice());

  
  // Delivary cost calculation
  const withDelivaryCost = () => {
    if (netOder < 50) {
      const netTotal = netOder + delivaryAmout;
      return netTotal.toFixed(2);
    }
    if (netOder > 50) {
      const netTotal = netOder - 0;
      return netTotal.toFixed(2);
    } else {
      console.log("all good");
    }
  };

  //Delivary cost msg....

  {
    /* const shipingCost =  () => {
    let stotal = 0
    const shpCost = 15
    if (netOder < 50) {
       products.forEach((item) => (stotal += item.quantity ), 0);
       const shipOrgPrice = shpCost / stotal 
      return shipOrgPrice;
    } if (netOder > 50) {
      const shipOrgPrice = 0;
      return shipOrgPrice;
    }
  };


  const shipping_rate = shipingCost();
  console.log(shipping_rate, "Shipping cost");*/
  }

  const addShippingcostwith = () => {
    if (netOder < 50) {
      const shiCost = true;
      return shiCost;
    } 
  };

  const withShippingCost = addShippingcostwith();
  console.log(withShippingCost, "testing price with 15 AED");

   const addShippingcostwithOut = () => {
     if (netOder > 50) {
       const shiCost = true;
       return shiCost;
     }
   };

   const withOutShippingCost = addShippingcostwithOut();
   console.log(withOutShippingCost, "testing price with 0 AED");




  let FinalNetPrice = withDelivaryCost();

  console.log(FinalNetPrice, "final price for checkuot");
  // Messeage

  const massageDisplay = () => {
    if (netOder < 50) {
      let value = 50 - netOder;
      return `Add AED ${value.toFixed(
        2
      )} of eligible items to your order to qualify for FREE Shipping. `;
    } else {
      return "🥳  Woo.. hoo.. you eligible for free delivery 🚚 ";
    }
  };

  // Chnage color
  const deliveryChargeClass = netOder > 50 ? "delivery-charge" : "";
  //Hiding Total value
  const inital = netOder === 0 ? "hide" : "";
  //Hiding

  //const finalPrice = withDelivaryCost();

  const stripePromise = loadStripe(
    "pk_live_51NZq6pLFX6mtMcpcmmo6KvEu2tURioX0Ip3uTWUs0eZGPLe3qVUpyXcR3H27pfrkt7PQ1izb6sK5LTW2FmzEF4Sp00jGDUWGNH"
  );

  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      const res = await makeRequest.post("/orders", {
        products,
        withShippingCost,
        withOutShippingCost,
      });

      await stripe.redirectToCheckout({
        sessionId: res.data.stripeSession.id,
      });
    } catch (err) {
      console.log(err, "Stripe not get connect...");
    }
  };

  return (
    <>
      <div className="cart">
        {/*
        <div className="close">
          <button onClick={() => setClose(true)}>
            <CancelIcon className="close_icon" />
          </button>
        </div> */}

        <div className="cart_wrap" style={{ padding: "10px" }}>
          <div className="freeDel">
            <span>
              * Order value below <strong>AED 50</strong> delivary charges{" "}
              <strong>AED 15</strong>
              <br />* Order value above <strong>AED 50</strong> free home
              delivary
            </span>
          </div>
          <h2 className="cart-head">Product in your cart</h2>

          {products?.map((item) => (
            <div className="item" key={item.id}>
              <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
              <div className="details">
                <h5>{item.title}</h5>
                <p>{item.desc?.substring(0, 50)}</p>
                <div className="price">
                  <span>
                    Qty {item.quantity} x AED {item.price}
                  </span>
                </div>
              </div>
              <DeleteOutlinedIcon
                className="delete"
                onClick={() => dispatch(removeItem(item.id))}
              />
            </div>
          ))}
          <div className="total">
            <span>Products Total</span>
            <span>AED {totalPrice()}</span>
          </div>

{/*
          <div className="total">
            <span style={{ color: "orange" }}> 15% Discount </span>
            <span style={{ color: "orange" }}>AED - {dicountPrice()}</span>
          </div>

          */}

          <div className="total">
            <span>Delivery Charges</span>
            <span className={`textOrg ${deliveryChargeClass}`}>AED 15.00</span>
          </div>

          <div className="sub_total">
            <span>SUBTOTAL</span>
            <div className={`${inital}`}>
              <span>
                AED {withDelivaryCost() }
              </span>
            </div>
          </div>
          <div className="delivaryMsg">
            <span>{massageDisplay()}</span>
          </div>
          <button className="checkout" onClick={handlePayment}>
            PROCEED TO CHECKOUT
          </button>
          <span className="reset" onClick={() => dispatch(resetCart())}>
            Reset Cart
          </span>
        </div>
      </div>
    </>
  );
};

export default Cart;
