import React, { useEffect, useState } from "react";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import "./Home.scss";

import sample from "../../img/hero.mp4";
import one from "../../img/one.webm";
import two from "../../img/two.webm";
import three from "../../img/three.webm";
import backImg from "../../img/banner.jpg";
import { Link } from "react-router-dom";
import Newsletter from "../../components/Newsletter/Newsletter";
import Popupdv from "../../components/popup/Popup";
import SlideShow from "../../components/slideshow/SlideShow";
import Social from "../../components/Social/Social";
import Modal from "../../components/modal/Modal";
import wallpaper from "../../img/hero_b.jpg";
import MovingText from "../../components/movingText/MovingText";

const Home = () => {


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="max-width">
      <SlideShow />
      <div className="home">
        <div className="promo_banner">
          {/* <img src={require("../../img/hero_banner.jpg")} alt="" /> */}
        </div>
      </div>

      {/* <div
        className="slogan"
        style={{
          backgroundImage: `url(${wallpaper})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
        }}
      >
        <div className="text">
          <h1 className="gradient-text">
            Unlock the Exceptional in Every Mouthful. <br /> Our brand
            epitomizes embracing life to its fullest, <br /> with a crunch that
            outshines all others.
          </h1>
          <br />
          <Link to={"/xl"} onClick={scrollToTop}>
            <button className="button">Read More</button>
          </Link>
        </div>
      </div> */}

      <div className="image_galary">
        <div className="section_a">
          <img src={require("../../img/2x/qa01.jpg")} alt="" />
        </div>
        <div className="section_a">
          <img src={require("../../img/2x/qa02.jpg")} alt="" />
        </div>
        <div className="section_a">
          <img src={require("../../img/2x/qa03.jpg")} alt="" />
        </div>
        <div className="section_a">
          <img src={require("../../img/2x/qa04.jpg")} alt="" />
        </div>
        <div className="section_a">
          <img src={require("../../img/2x/qa05.jpg")} alt="" />
        </div>
        <div className="section_a">
          <img src={require("../../img/2x/qa06.jpg")} alt="" />
        </div>
      </div>
      <MovingText />
      <Newsletter />
    </div>
  );
};

export default Home;
