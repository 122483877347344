import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import "./Card.scss";


const Card = ({ item }) => {

  return (
    <Link to={`/product/${item.id}`}>
      <div className="card">
        <div className="card_in">
          <div className="image">
            {item.attributes.discount && (
              <span> {item.attributes.discoamount}</span>
            )}
            <span2> {item.attributes.outofstock && "out of stock"} </span2>
            <img
              src={
                process.env.REACT_APP_UPLOAD_URL +
                item.attributes?.img?.data?.attributes?.url
              }
              alt=""
              className="mainImg"
            />
            <img
              src={
                process.env.REACT_APP_UPLOAD_URL +
                item.attributes?.img2?.data?.attributes?.url
              }
              alt=""
              className="secondImg"
            />
          </div>

          <div className="data">
            <p>
              {item.attributes.title}
               {/*

              <span style={{ color: "black" }}>
                {" "}
                | {item.attributes.weight}
              </span>
            */}
            </p>
            {/*
            <div className="prices">
              <h2>{item?.attributes.oldprice}</h2>
              
              <span style={{ fontSize: "20px" }}>
                <h7> AED </h7>
                {item?.attributes.price}
              </span>
              <h1>
              {
                item?.attributes.price - (Math.round (item?.attributes.price * 15) / 100)}
            </h1>
            </div>
            */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
