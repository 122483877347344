import React from 'react'
import './Snacks.scss'
import SnackBrandHead from '../../components/snackHead/SnackBrandHead';

const Snacks = () => {
  return (
    <div className="confectionery_sn">
      <div style={{ paddingTop: "50px" }}>
        <SnackBrandHead />
      </div>
      <div className="__container">
        <div className="__con_head">
          <p>The multiverse of Snacks</p>
        </div>
        <div className="__con_main_cat">
          <a href="#1">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_01.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Fresh Potato Chips​</h2>​
              </div>
            </div>
          </a>

          <a href="#2">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_02.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Tortilla Chips​</h2>​
              </div>
            </div>
          </a>

          <a href="#3">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_03.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Wavy Chips​​</h2>​
              </div>
            </div>
          </a>

          <a href="#4">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_04.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Potato Sticks​​</h2>​
              </div>
            </div>
          </a>

          <a href="#5">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_05.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Puffed Snacks​​​​</h2>​
              </div>
            </div>
          </a>

          <a href="#6">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_06.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Baked Chips​​</h2>​
              </div>
            </div>
          </a>

          <a href="#7">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_07.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Extruded Tube Shaped​​​</h2>​
              </div>
            </div>
          </a>

          <a href="#8">
            <div className="__con_cat_card_b">
              <div className="__con_cat_img">
                <img src={require("../../img/ch_08.webp")} />
              </div>
              <div className="__con__cat_text">
                <h2>Extruded Cone Shaped​​​</h2>​
              </div>
            </div>
          </a>
        </div>
      </div>

      <div className="full_width">
        <div className="conf_wrap __container" id="1">
          <div className="conf_img I_a">
            <img src={require("../../img/potato.png")} />
          </div>
          <div className="conf_txt I_b">
            <div className="conf_text_head">
              <h1>Fresh Potato Chips​</h1>
              <h2>Lightly seasoned</h2>
            </div>
            <p>
              Our Fresh Potato Chips are crafted from select quality potatoes,
              thinly sliced and crisped to perfection. Lightly seasoned, they
              offer an authentic, homemade taste.​
              <br />
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap __container" id="2">
          <div className="conf_txt I_c">
            <div className="conf_text_head">
              <h1>Tortilla Chips​</h1>
              <h2>Perfect blend of crunch and flavor</h2>
            </div>
            <p>
              Made from premium corn, our Tortilla Chips are the perfect blend
              of crunch and flavor. Ideal for dipping or enjoying on their own,
              they are a staple in any snack arsenal.​
            </p>
          </div>
          <div className="conf_img I_d">
            <img src={require("../../img/Tortilla.webp")} />
          </div>
        </div>
      </div>

      <div className="full_width">
        <div className="conf_wrap __container" id="3">
          <div className="conf_img I_e">
            <img src={require("../../img/Wavy.webp")} />
          </div>
          <div className="conf_txt I_f">
            <div className="conf_text_head">
              <h1>Wavy Chips​</h1>
              <h2> Crunch with our Wavy Chips</h2>
            </div>
            <p>
              Experience layers of crunch with our Wavy Chips. These undulating
              potato slices are seasoned to bring out their natural goodness,
              adding a new dimension to the conventional ​
              <br />
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap __container" id="4">
          <div className="conf_txt I_g">
            <div className="conf_text_head">
              <h1>Potato Sticks​</h1>
              <h2>Potato Sticks are slender, crispy</h2>
            </div>
            <p>
              A unique twist on classic potato chips, our Potato Sticks are
              slender, crispy, and irresistibly tasty, offering a fun way to
              enjoy the simple goodness of potatoes. Available in a variety of
              packaging's included pouches and Cans.
              <br />
            </p>
          </div>
          <div className="conf_img i_h">
            <img src={require("../../img/sticks.png")} />
          </div>
        </div>
      </div>

      <div className="full_width">
        <div className="conf_wrap  __container" id="5">
          <div className="conf_img I_a">
            <img src={require("../../img/Puffed.webp")} />
          </div>
          <div className="conf_txt I_b ">
            <div className="conf_text_head">
              <h1>Puffed Snacks​</h1>
              <h2>Made from high-quality grains</h2>
            </div>
            <p>
              Our Puffed Snacks offer a light, airy crunch and come in a variety
              of exciting flavors. Made from high-quality grains, they are a
              healthier alternative for those seeking to satisfy their snack
              cravings.​
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap  __container" id="6">
          <div className="conf_txt I_c ">
            <div className="conf_text_head">
              <h1>Baked Chips​</h1>
              <h2>Oven-baked to perfection</h2>
            </div>
            <p>
              For the health-conscious consumer, our Baked Chips offer all the
              flavor without the guilt. Oven-baked to perfection, they are a
              lower-fat alternative to traditional fried chips with a crispier
              and crunchier bite.​
              <br />
            </p>
          </div>
          <div className="conf_img I_d">
            <img src={require("../../img/Baked.webp")} />
          </div>
        </div>
      </div>

      <div className="full_width">
        <div className="conf_wrap __container" id="7">
          <div className="conf_img I_e ">
            <img src={require("../../img/Extruded.webp")} />
          </div>
          <div className="conf_txt I_f">
            <div className="conf_text_head">
              <h1>Extruded Tube Shaped​</h1>
              <h2>Exquisitely seasoned</h2>
            </div>
            <p>
              Perfectly cylindrical and exquisitely seasoned, our Extruded Tube
              Snacks are a hit among those who like their snacks to come with a
              twist—literally.​
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="conf_wrap  __container" id="8">
          <div className="conf_txt I_g">
            <div className="conf_text_head">
              <h1>Extruded Corn-Shaped Snacks</h1>
              <h2>Bursting with flavor</h2>
            </div>
            <p>
              Molded into delightful corn shapes, these extruded snacks are not
              just visually appealing but are also bursting with flavor,
              offering a truly unique snacking experience.
            </p>
          </div>
          <div className="conf_img I_h">
            <img src={require("../../img/Cone .webp")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Snacks
